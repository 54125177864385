import { Box, Stack, Paper, Typography, Skeleton, List, ListItem, ListItemText, ListItemAvatar } from "@mui/material";
import InfoForm from "./InfoForm";

export default function RenderError() {
  return (
    <Stack
      spacing={4}
      sx={{
        mx: "auto",
        maxWidth: "800px",
        px: 1,
        py: 4,
      }}
    >
      <Paper variant="outlined" sx={{ backgroundColor: "#fafafa", p: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 500, color: "#2789E3" }}>
          航次資訊
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "2rem", p: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: { xs: "column", sm: "row" },
              gap: 3,
            }}
          >
            <InfoForm text="航次代號" loading />
            <InfoForm text="領隊教授" loading />
          </Box>
          <InfoForm text="探測海域" loading />
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexDirection: { xs: "column", sm: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 3,
                flexDirection: { xs: "column", sm: "row" },
                width: "100%",
              }}
            >
              <InfoForm text="航行浬程 (海浬)" loading />
              <InfoForm text="航行天數" loading />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                flexDirection: { xs: "column", sm: "row" },
                width: "100%",
              }}
            >
              <InfoForm text="航行時數" loading />
              <InfoForm text="離岸最遠 (海浬)" loading />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <InfoForm text="出港日期 (UTC+8)" loading />
            <InfoForm text="出港港口" loading />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <InfoForm text="進港日期 (UTC+8)" loading />
            <InfoForm text="進港港口" loading />
          </Box>
          <InfoForm text="計畫名稱" loading />
          <InfoForm text="探測人員" loading />
          <InfoForm text="備註 (作業狀況說明及建議事項)" loading />
        </Box>
      </Paper>
      <Paper variant="outlined" sx={{ backgroundColor: "#fafafa", p: 2 }}>
        {/* #F8FaFC*/}

        <Typography variant="h6" sx={{ fontWeight: 500, color: "#2789E3" }}>
          參與人員
        </Typography>
        <List>
          <ListItem sx={{ gap: 2 }}>
            <ListItemAvatar>
              <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText
              primary={""}
              secondary={
                <Skeleton
                  variant="rounded"
                  height={60}
                  sx={{ width: "100%" }}
                ></Skeleton>
              }
            />
          </ListItem>
        </List>
      </Paper>
      <Paper variant="outlined" sx={{ backgroundColor: "#fafafa", p: 2 }}>
        {/* #F8FaFC*/}
        <Typography variant="h6" sx={{ fontWeight: 500, color: "#2789E3" }}>
          作業項目
        </Typography>
        <Box sx={{ px: 2 }}>
          <Skeleton sx={{ width: "100%" }} height={180} />
        </Box>
      </Paper>
    </Stack>
  );
}
