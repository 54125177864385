import React from "react";
import { useQuery } from "react-query";
import { Box, Typography, Stack, Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider } from "@mui/material";
import AppendTable from "./AppendTable";
import RenderError from "./RenderError";
import CruiseEquiTable from "./CruiseEquiTable";
import InfoForm from "./InfoForm";
import logos from "../assets/logos";

function formatDateAndTime(isoDateString) {
  if (!isoDateString) return "";
  const date = new Date(isoDateString);
  return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getUTCDate()).padStart(2, "0")} ${String(
    date.getUTCHours()
  ).padStart(2, "0")}:${String(date.getUTCMinutes()).padStart(2, "0")}:${String(
    date.getUTCSeconds()
  ).padStart(2, "0")}`;
}
const useCruiseInfo = (shipName, cruiseID) => {
  return useQuery(["cruiseInfo", shipName, cruiseID], async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `${shipName}/${cruiseID}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
};

export default function CruiseInfo({ shipName, cruiseID }) {
  const {
    data: cruiseData,
    isLoading,
    isError,
  } = useCruiseInfo(shipName, cruiseID);

  if (isLoading || isError || !cruiseData || cruiseData.length === 0) {
    return (
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 500, color: "#71A1C" }}>
          {isLoading
            ? "Loading"
            : isError
            ? "資料庫連線異常，請稍後再試"
            : "探測報告不存在於資料庫"}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 400, color: "#474747" }}
        >
          (以下資料為人工謄填，僅供參考，正確內容請以各船務室書面正本為主)
        </Typography>
        <RenderError />
      </Box>
    );
  }

  const renderListItems = (items, itemType) =>
    items.map((item, index) => (
      <React.Fragment key={index}>
        <ListItem sx={{ gap: 2 }}>
          <ListItemAvatar>
            <Avatar alt={item || "Unknown"} src={logos[item]}>
              {item?.charAt(0)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={item || ""}
            secondary={
              <Typography sx={{ color: "#474747" }}>
                {cruiseData[0].Participants.Name[index]}
              </Typography>
            }
          />
        </ListItem>
        {index < items.length - 1 && (
          <Divider variant="middle" component="li" />
        )}
      </React.Fragment>
    ));

  // Render the actual content if data is available...

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 500, color: "#71A1C" }}>
        探測報告表
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 400, color: "#474747" }}
      >
        (以下資料為人工謄填，僅供參考，正確內容請以各船務室書面正本為主)
      </Typography>
      <Stack spacing={4} sx={{ mx: "auto", maxWidth: "800px", px: 1, py: 4 }}>
        <Paper variant="outlined" sx={{ backgroundColor: "#fafafa", p: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 500, color: "#2789E3" }}>
            航次資訊
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "2rem", p: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: { xs: "column", sm: "row" },
                gap: 3,
              }}
            >
              <InfoForm
                text="航次代號"
                info={`${cruiseData?.[0]?.CruiseBasicData?.ShipName}${cruiseData?.[0]?.CruiseBasicData?.CruiseID}`}
              />
              <InfoForm
                text="領隊教授"
                info={cruiseData[0].CruiseBasicData.LeaderName}
              />
            </Box>
            <InfoForm
              text="探測海域"
              info={cruiseData[0].CruiseBasicData.ExploreOcean}
              sx={{ flexBasis: { sm: "100%", md: 0, lg: 0 } }}
            />
            <Box
              sx={{
                display: "flex",
                gap: 3,
                flexDirection: { xs: "column", sm: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  flexDirection: { xs: "column", sm: "row" },
                  width: "100%",
                }}
              >
                <InfoForm
                  text="航行浬程 (海浬)"
                  info={cruiseData[0].CruiseBasicData.TotalDistance}
                />
                <InfoForm
                  text="航行天數"
                  info={cruiseData[0].CruiseBasicData.DurationDays}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  flexDirection: { xs: "column", sm: "row" },
                  width: "100%",
                }}
              >
                <InfoForm
                  text={"航行時數"}
                  info={cruiseData[0].CruiseBasicData.DurationHours}
                />
                <InfoForm
                  text={"離岸最遠 (海浬)"}
                  info={cruiseData[0].CruiseBasicData.FarestDistance}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <InfoForm
                text={"出港日期 (UTC+8)"}
                info={formatDateAndTime(
                  cruiseData[0].CruiseBasicData.StartDate
                )}
              />
              <InfoForm
                text={"出港港口"}
                info={cruiseData[0].CruiseBasicData.StartPort}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <InfoForm
                text={"進港日期 (UTC+8)"}
                info={formatDateAndTime(cruiseData[0].CruiseBasicData.EndDate)}
              />
              <InfoForm
                text={"進港港口"}
                info={cruiseData[0].CruiseBasicData.EndPort}
              />
            </Box>

            <InfoForm
              text={"計畫名稱"}
              info={cruiseData[0].CruiseBasicData.PlanName}
            />
            <InfoForm
              text={"探測人員"}
              info={cruiseData[0].CruiseBasicData.Technician}
            />

            <InfoForm
              text={"備註 (作業狀況說明及建議事項)"}
              info={cruiseData[0].CruiseBasicData.Remark}
            />
          </Box>
        </Paper>
        <Paper variant="outlined" sx={{ backgroundColor: "#fafafa", p: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 500, color: "#2789E3" }}>
            參與人員
          </Typography>
          <List>{renderListItems(cruiseData[0].Participants.Department)}</List>
        </Paper>
        <Paper variant="outlined" sx={{ backgroundColor: "#fafafa", p: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 500, color: "#2789E3" }}>
            作業項目
          </Typography>
          <Box sx={{ p: 2 }}>
            {cruiseData[0].CruiseData && (
              <CruiseEquiTable data={cruiseData[0].CruiseData} />
            )}
            {cruiseData[0].Physical && (
              <AppendTable title={"物理作業"} data={cruiseData[0].Physical} />
            )}
            {cruiseData[0].Biogeochemical && (
              <AppendTable
                title={"生地化作業"}
                data={cruiseData[0].Biogeochemical}
              />
            )}
            {cruiseData[0].Biology && (
              <AppendTable title={"生物作業"} data={cruiseData[0].Biology} />
            )}
            {cruiseData[0].Geology && (
              <AppendTable title={"地質作業"} data={cruiseData[0].Geology} />
            )}
            {cruiseData[0].Geophysics && (
              <AppendTable title={"地物作業"} data={cruiseData[0].Geophysics} />
            )}
            {cruiseData[0].Atmospher && (
              <AppendTable title={"大氣作業"} data={cruiseData[0].Atmospher} />
            )}
            {cruiseData[0].Other && (
              <AppendTable title={"其他作業"} data={cruiseData[0].Other} />
            )}
          </Box>
        </Paper>
      </Stack>
    </Box>
  );
}
