import NTUlogo from "./NTU.gif";
import SYUlogo from "./SYU.png";
import NKUSTlogo from "./NKUST.png";
import NKNUlogo from "./NKNU.png";
import marinelogo from "./marine.png";
import NTOUlogo from "./ntoulogo.gif";
import Sinicalogo from "./sinica.png";
import NCKUlogo from "./ncku.png";
import NTNUlogo from "./NTNU.png";
import NAMRlogo from "./NAMR.png";
import DWUlogo from "./DWU.jpg";
import NTUElogo from "./NTUE.png";
import cwalogo from "./cwa.png";
import MElogo from "./ME.png";
import dhlogo from "./NDHU.png";
import nmnslogo from "./NMNS.png";
import NCHUlogo from "./NCHU.png";
import TEPtechlogo from "./TPEtech.png";
import PHlogo from "./ph.png";
import ChiaYilogo from "./chiayi.png";
import KSUlogo from "./ksu.png";
import NUTNlogo from "./nutn.png";
import OCAlogo from "./Ocalogo.jpg";
import NMMBAlogo from "./nmmba.png";
import NPUSTlogo from "./NPUST.png";
import KHlogo from "./KH.png";
import TORIlogo from "./tori.png";
import TOUlogo from "./tou.png";
import TKUlogo from "./TKU.png";

const logos = {
  "台大": NTUlogo,
  "臺大": NTUlogo,
  "臺灣大學": NTUlogo,
  "台灣大學": NTUlogo,
  "國立臺灣大學": NTUlogo,
  "國立台灣大學": NTUlogo,
  "台大海研所": NTUlogo,
  "台大海洋所": NTUlogo,
  "台灣大學海洋所": NTUlogo,
  "台大地質所": NTUlogo,
  "中山": SYUlogo,
  "中山大學": SYUlogo,
  "國立中山大學": SYUlogo,
  "中山大學海生所": SYUlogo,
  "中山大學海科院": SYUlogo,
  "中山大學海科院海研三號": SYUlogo,
  "中山大學海資系": SYUlogo,
  "中山海資": SYUlogo,
  "中山海科系": SYUlogo,
  "中山大學海地化所": SYUlogo,
  "中山海地化": SYUlogo,
  "中山大學海下所": SYUlogo,
  "中山大學海科院海地化所": SYUlogo,
  "中山大學海工系": SYUlogo,
  "高科大": NKUSTlogo,
  "國立高雄科技大學": NKUSTlogo,
  "高雄科技大學": NKUSTlogo,
  "高雄科技": NKUSTlogo,
  "高師大": NKNUlogo,
  "海軍官校": marinelogo,
  "海軍官校應用科學系": marinelogo,
  "海軍軍官學校": marinelogo,
  "海大": NTOUlogo,
  "海洋大學": NTOUlogo,
  "國立海洋大學": NTOUlogo,
  "臺灣海洋大學": NTOUlogo,
  "國立臺灣海洋大學": NTOUlogo,
  "海洋大學漁業科學所": NTOUlogo,
  "海洋大學應用地球物理所": NTOUlogo,
  "中研院": Sinicalogo,
  "中央研究院": Sinicalogo,
  "成大": NCKUlogo,
  "成功大學": NCKUlogo,
  "國立成功大學": NCKUlogo,
  "成功大學水工所": NCKUlogo,
  "師大": NTNUlogo,
  "國立師範大學": NTNUlogo,
  "師範大學": NTNUlogo,
  "台師大": NTNUlogo,
  "臺師大": NTNUlogo,
  "臺灣師範大學": NTNUlogo,
  "國海院": NAMRlogo,
  "國家海洋研究院": NAMRlogo,
  "東吳大學": DWUlogo,
  "東吳": DWUlogo,
  "北教大": NTUElogo,
  "中央氣象局": cwalogo,
  "氣象局": cwalogo,
  "環保署": MElogo,
  "環境部": MElogo,
  "東華大學": dhlogo,
  "國立東華大學海洋生物研究": dhlogo,
  "國立東華大學": dhlogo,
  "東華": dhlogo,
  "科博館": nmnslogo,
  "中興大學": NCHUlogo,
  "臺北科技大學": TEPtechlogo,
  "澎湖科大": PHlogo,
  "澎湖科技大學": PHlogo,
  "澎科大": PHlogo,
  "嘉義大學": ChiaYilogo,
  "崑山大學": KSUlogo,
  "台南大學": NUTNlogo,
  "台南大": NUTNlogo,
  "臺南大學": NUTNlogo,
  "海保署": OCAlogo,
  "海生館": NMMBAlogo,
  "國立海洋生物博物館": NMMBAlogo,
  "屏科大": NPUSTlogo,
  "屏東科技大學": NPUSTlogo,
  "國立屏東科技大學": NPUSTlogo,
  "高醫大": KHlogo,
  "國研院":TORIlogo,
  "TORI": TORIlogo,
  "海洋科技研究中心": TORIlogo,
  "國研院海科中心": TORIlogo,
  "TOU": TOUlogo,
  "臺灣海洋聯盟(TOU)": TOUlogo,
  "臺灣海洋聯盟": TOUlogo,
  "淡大": TKUlogo
};

export default logos;